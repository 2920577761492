import React from "react";

import { useAppSelector } from "store";

const NavigationBreadcrumbs: React.FC = () => {
	const formName = useAppSelector((state) => state.form.active?.name);
	const fieldReferenceLibrary = useAppSelector((state) => state.form.fieldReferenceLibrary);
	const { list } = useAppSelector((state) => state.history);

	const breadCrumbs = [
		formName,
		...list
			.map((nameOrIdx) => {
				return fieldReferenceLibrary[nameOrIdx]?.shortLabel || fieldReferenceLibrary[nameOrIdx]?.label || (/^[0-9]+$/.test(nameOrIdx) ? `#${parseInt(nameOrIdx) + 1}` : nameOrIdx);
			})
			.slice(0, -1),
	].filter((label): label is string => !!label);


	const summarizedBreadCrums =
		breadCrumbs.length > 4 ? [...breadCrumbs.slice(0, 2), "...", ...breadCrumbs.slice(-2, breadCrumbs.length)] : breadCrumbs;

	if (list.length === 0) return <></>;

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "2rem",
				color: "#718096",
				width: "100%",
				padding: "0.5rem 1.5rem",
				backgroundColor: "#EDF2F7",
			}}
		>
			<div
				style={{
					height: "100%",
					fontWeight: "400",
					fontSize: "0.7rem",
					overflow: "hidden",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					direction: "rtl",
					textAlign: "end",
					width: "100%",
				}}
			>
				{summarizedBreadCrums.join(" / ")}
			</div>
		</div>
	);
};

export default NavigationBreadcrumbs;
