const config = {
	//API: "https://api.dev.aite.dhub.arup.com",
	//API: "https://api.aite.dhub.arup.com",
	API: "http://localhost:5000",
	S3_IMAGES: "", // Irrelevant since it won't accept localhost requests anyway
	AWS_REGION: "eu-west-1",
	AWS_COGNITO_DOMAIN: "adifiteauth.auth.eu-west-1.amazoncognito.com",
	AWS_COGNITO_POOL_ID: "eu-west-1_6zrfPeXrO",
	AWS_COGNITO_WEB_CLIENT_ID: "6dgpaqnhv9n45dtcn9n17amohc",
	// AWS_COGNITO_DOMAIN: "dhubauth.auth.eu-west-1.amazoncognito.com",
	// AWS_COGNITO_POOL_ID: "eu-west-1_ehMZjDbJz",
	// AWS_COGNITO_WEB_CLIENT_ID: "1eari6fm44ud133hvhtljqchgt",
	RELEASE: process.env.REACT_APP_GIT_SHA?.slice(0, 7) || "development",
	VAPID_PUBLIC_KEY: "BNyH2hFG1vGOr1lOEjKiSZq9nMF4TJGbUXJSb4A5mWLpOehY0Ip_8ZeEjRNVCREZNI0GdCCFHPD6hXOLVNDo4zA",
};

export default config;
