import React, { memo, PropsWithChildren } from "react";

import { IonCheckbox, IonIcon, IonItem, IonLabel, IonNote, IonText } from "@ionic/react";
import { Controller, UseFormReturn } from "react-hook-form";

import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";

import useFieldTemplate from "../hooks/useFieldTemplate";
import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";

interface IProps {
	field: FormField<string[]>;
	formMethods: UseFormReturn<FormValues>;
}

type PropsType = IProps;
const MultiRadioGroup: React.FC<PropsType> = (props) => {
	const { field, formMethods } = props;
	const { name, alert, isValid, isRelevant, isRequired, isDisabled, isHardRequired, isFrozen } = useFieldTemplate(
		field,
		formMethods.control,
	);

	const validChoices = (field.choices || []).filter((choice) => isValid({ choice }));
	if (field.sortChoices === "ascending") validChoices.sort((a, b) => a.label.localeCompare(b.label));
	else if (field.sortChoices === "descending") validChoices.sort((a, b) => a.label.localeCompare(b.label));

	const value = formMethods.getValues(name);
	const [originalValue,] = React.useState(value as string[] | null | undefined);

	return (
		<div
			className="Input"
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{isRelevant() && (
				<Controller
					name={name}
					control={formMethods.control}
					rules={{
						required: isHardRequired() && isRelevant(),
					}}
					defaultValue={((field.defaultValue as string[]) || []).map(String)}
					render={({ field: fieldRenderProps }) => {
						const currentValue = fieldRenderProps.value as string[] | undefined;
						const validChoiceValues = validChoices.map(it => it.value);
						const invalidLegacyValues = originalValue?.filter(it => !validChoiceValues.includes(it))
						const hasInvalidLegacyValues = invalidLegacyValues?.length && currentValue === originalValue

						return (
							<IonItem
								lines="none"
								style={{
									backgroundColor: "transparent",
								}}
								disabled={isFrozen || isDisabled()}
							>
								<IonLabel className="ion-text-wrap" position="stacked" mode="ios" style={{ paddingBottom: "0.1rem" }}>
									{field.label}
									{(isRequired() || isHardRequired()) && <span style={{ color: "red" }}>&nbsp;*</span>}
								</IonLabel>
								<div
									// React Hook Form Managed
									ref={fieldRenderProps.ref}
									style={{
										width: "100%",
										marginTop: "0.1rem",
									}}
								>
									{validChoices.map((choice, i) => (
										<IonItem
											key={`${field.name}-choice-${i}`}
											lines="none"
											style={
												{
													"--inner-padding-start": "0",
													"--inner-padding-end": "1rem",
												} as React.CSSProperties
											}
										>
											<IonCheckbox
												aria-label={`${field.type}:${choice.value}`}
												data-testid={`${field.type}:checkbox:${choice.value}`}
												mode="md"
												slot="start"
												color="dark"
												name={choice.label}
												value={choice.value}
												checked={currentValue && currentValue.includes(choice.value)}
												onIonChange={(e) => {
													setTimeout(() => {
														// TODO: this is to solve a race condition
														if (e.detail.checked) fieldRenderProps.onChange([...(currentValue || []), e.detail.value]);
														else fieldRenderProps.onChange((currentValue || []).filter((v) => v !== e.detail.value));
													}, 0);
												}}
												onIonBlur={fieldRenderProps.onBlur}
												labelPlacement="end"
												justify="start"
												style={{
													marginInlineEnd: ".5rem",
													width: "100%",
												}}
											>
												<span className="ion-text-wrap">{choice.label}</span>
											</IonCheckbox>
										</IonItem>
									))}
									{hasInvalidLegacyValues ? invalidLegacyValues.map((invalidLegacyValue, idx) => (
										<IonItem
											key={`${field.name}-legacy-choice-${idx}`}
											lines="none"
											style={
												{
													"--inner-padding-start": "0",
													"--inner-padding-end": "1rem",
												} as React.CSSProperties
											}
										>
											<IonCheckbox
												aria-label={`${field.type}:${invalidLegacyValue}`}
												data-testid={`${field.type}:checkbox:${invalidLegacyValue}`}
												mode="md"
												slot="start"
												color="dark"
												name={invalidLegacyValue}
												value={invalidLegacyValue}
												checked={currentValue && currentValue.includes(invalidLegacyValue)}
												onIonChange={(e) => {
													setTimeout(() => {
														// TODO: this is to solve a race condition
														if (e.detail.checked) fieldRenderProps.onChange([...(currentValue || []), e.detail.value]);
														else fieldRenderProps.onChange((currentValue || []).filter((v) => v !== e.detail.value));
													}, 0);
												}}
												onIonBlur={fieldRenderProps.onBlur}
												labelPlacement="end"
												justify="start"
												style={{
													marginInlineEnd: ".5rem",
													width: "100%",
												}}
											>
												<span className="ion-text-wrap">{invalidLegacyValue + " (No válido)"}</span>
											</IonCheckbox>
										</IonItem>
									)
									) : undefined}
								</div>
								{hasInvalidLegacyValues && (
									<IonNote
										data-testid={`${field.type}:legacy-note`}
										color="danger"
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											margin: ".25rem 0",
											fontSize: ".7rem",
										}}
									>
										<IonIcon
											icon={informationCircleOutline}
											color="danger"
											size="small"
										/>
										<IonText>Algunos de los valores seleccionados no se ajustan a la especificación actual, y se muestran solo a efectos informativos. Por favor, revise y actualice este campo.</IonText>
									</IonNote>
								)}
								{alert && (
									<IonNote
										data-testid={`${field.type}:note`}
										color={field.alertColor}
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											margin: ".25rem 0",
											fontSize: ".7rem",
										}}
									>
										<IonIcon
											icon={
												field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
											}
											color={field.alertColor}
											size="small"
										/>
										<IonText>{alert}</IonText>
									</IonNote>
								)}
							</IonItem>
						);
					}}
				/>
			)}
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(MultiRadioGroup, propsAreEqual);
export default Memoized;
