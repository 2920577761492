import React, { memo, PropsWithChildren } from "react";

import { IonIcon, IonItem, IonLabel, IonNote, IonRadio, IonRadioGroup, IonText } from "@ionic/react";
import { Controller, UseFormReturn } from "react-hook-form";

import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";

import useFieldTemplate from "../hooks/useFieldTemplate";
import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";

interface IProps {
	field: FormField<string>;
	formMethods: UseFormReturn<FormValues>;
}

type PropsType = IProps;
const RadioGroup: React.FC<PropsType> = (props) => {
	const { field, formMethods } = props;
	const { name, alert, isValid, isRelevant, isRequired, isDisabled, isHardRequired, isFrozen } = useFieldTemplate(
		field,
		formMethods.control,
	);

	// This is necessary because when the current value is false the required rule was giving a false error.
	// This can happen when you have a RadioGroup with true and false choices.
	const currentValue = formMethods.watch(name);
	const valueIsBoolean = typeof currentValue === "boolean";

	const [originalValue,] = React.useState(typeof currentValue === "string" ? currentValue as string : undefined);

	const validChoices = (field.choices || []).filter((choice) => isValid({ choice }));
	if (field.sortChoices === "ascending") validChoices.sort((a, b) => a.label.localeCompare(b.label));
	else if (field.sortChoices === "descending") validChoices.sort((a, b) => a.label.localeCompare(b.label));

	return (
		<div
			className="Input"
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{isRelevant() && (
				<Controller
					name={name}
					control={formMethods.control}
					rules={{
						required: {
							value: isHardRequired() && isRelevant() && !valueIsBoolean,
							message: "RadioGroup hardRequired",
						},
					}}
					defaultValue={field.defaultValue !== undefined ? String(field.defaultValue) : undefined}
					render={({ field: fieldRenderProps }) => {

						const hasInvalidLegacyValue = originalValue
							&& fieldRenderProps.value === originalValue
							&& !validChoices.map(it => it.value).includes(originalValue as string);

						return (
							<IonItem
								lines="none"
								disabled={isFrozen || isDisabled()}
								style={{
									backgroundColor: "transparent",
								}}
							>
								<IonLabel className="ion-text-wrap" position="stacked" mode="ios" style={{ paddingBottom: "0.1rem" }}>
									{field.label}
									{(isRequired() || isHardRequired()) && <span style={{ color: "red" }}>&nbsp;*</span>}
								</IonLabel>
								<IonRadioGroup
									// React Hook Form Managed
									ref={fieldRenderProps.ref}
									name={fieldRenderProps.name}
									value={fieldRenderProps.value}
									onIonChange={(e) => {
										setTimeout(() => {
											fieldRenderProps.onChange(e); // TODO: this is to solve a race condition
										}, 0);
									}}
									// Other config
									placeholder={field.description}
									style={{
										width: "100%",
										marginTop: "0.1rem",
									}}
								>
									{validChoices.map((choice, i) => (
										<IonItem
											key={`${field.name}-choice-${i}`}
											lines="none"
											disabled={isDisabled({ choice })}
											style={
												{
													"--inner-padding-start": "0",
													"--inner-padding-end": "1rem",
												} as React.CSSProperties
											}
										>
											<IonRadio
												data-testid={`${field.type}:checkbox:${choice.value}`}
												mode="md"
												labelPlacement="end"
												name={choice.label}
												value={choice.value}
												onIonBlur={fieldRenderProps.onBlur}
												justify="start"
												style={{
													marginInlineEnd: ".5rem",
													width: "100%",
												}}
											>
												<span className="ion-text-wrap" data-testid={`${field.type}:label:${choice.value}`}>
													{choice.label}
												</span>
											</IonRadio>
										</IonItem>
									))}
									{hasInvalidLegacyValue ? (
										<IonItem
											key={`${field.name}-choice-legacy`}
											lines="none"
											disabled={true}
											style={
												{
													"--inner-padding-start": "0",
													"--inner-padding-end": "1rem",
												} as React.CSSProperties
											}
										>
											<IonRadio
												data-testid={`${field.type}:checkbox:legacy`}
												mode="md"
												labelPlacement="end"
												name={originalValue}
												value={originalValue}
												onIonBlur={fieldRenderProps.onBlur}
												justify="start"
												style={{
													marginInlineEnd: ".5rem",
													width: "100%",
												}}
											>
												<span className="ion-text-wrap" data-testid={`${field.type}:label:legacy`}>
													{originalValue}
												</span>
											</IonRadio>
										</IonItem>
									) : undefined}
								</IonRadioGroup>
								{hasInvalidLegacyValue && (
									<IonNote
										data-testid={`${field.type}:legacy-note`}
										color="danger"
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											margin: ".25rem 0",
											fontSize: ".7rem",
										}}
									>
										<IonIcon
											icon={informationCircleOutline}
											color="danger"
											size="small"
										/>
										<IonText>El valor seleccionado no se ajusta a la especificación actual, y se muestra solo a efectos informativos. Por favor, revise y actualice este campo.</IonText>
									</IonNote>
								)}
								{alert && (
									<IonNote
										data-testid={`${field.type}:note`}
										color={field.alertColor}
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											margin: ".25rem 0",
											fontSize: ".7rem",
										}}
									>
										<IonIcon
											icon={
												field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
											}
											color={field.alertColor}
											size="small"
										/>
										<IonText>{alert}</IonText>
									</IonNote>
								)}
							</IonItem>
						);
					}}
				/>
			)}
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(RadioGroup, propsAreEqual);
export default Memoized;
