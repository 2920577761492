import React, { memo, PropsWithChildren } from "react";

import { IonSelect, IonSelectOption, IonItem, IonLabel, IonNote, IonIcon, IonText } from "@ionic/react";
import { Controller, UseFormReturn } from "react-hook-form";

import { Divider } from "components/common/Form/components/Divider";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";

import useFieldTemplate from "../hooks/useFieldTemplate";
import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";

interface IProps {
	field: FormField<string>;
	formMethods: UseFormReturn<FormValues>;
}

type PropsType = IProps;
const Select: React.FC<PropsType> = (props) => {
	const { field, formMethods } = props;
	const { name, alert, isValid, isRelevant, isRequired, isDisabled, isHardRequired, isFrozen } = useFieldTemplate(
		field,
		formMethods.control,
	);

	const validChoices = (field.choices || []).filter((choice) => isValid({ choice }));
	if (field.sortChoices === "ascending") validChoices.sort((a, b) => a.label.localeCompare(b.label));
	else if (field.sortChoices === "descending") validChoices.sort((a, b) => a.label.localeCompare(b.label));

	const value = formMethods.getValues(name);
	const [originalValue,] = React.useState(value);

	return (
		<div
			className="Input"
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{isRelevant() && (
				<Controller
					name={name}
					control={formMethods.control}
					defaultValue={field.defaultValue}
					rules={{
						required: isHardRequired() && isRelevant() && validChoices.length !== 0,
					}}
					render={({ field: fieldRenderProps }) => {
						// Timeout prevents a form re-render before first render finishes
						setTimeout(() => {
							// If value is no longer valid
							if (
								fieldRenderProps.value &&
								!validChoices.map((ch) => ch.value).includes(fieldRenderProps.value as string)
							) {
								// Keep original value even if no longer valid (legacy values)
								if (originalValue === fieldRenderProps.value) return
								// If not a legacy value, unset
								fieldRenderProps.onChange(null);
							}
							// If only one valid choice
							if (validChoices.length === 1 && fieldRenderProps.value !== validChoices[0].value) {
								fieldRenderProps.onChange(validChoices[0].value);
							}
						}, 0);

						const hasInvalidLegacyValue = originalValue
							&& fieldRenderProps.value === originalValue
							&& !validChoices.map(it => it.value).includes(originalValue as string);

						return (
							<IonItem
								lines="none"
								cy-data="OptionSelector"
								disabled={isFrozen || isDisabled() || (validChoices.length === 0 && !fieldRenderProps.value)}
							>
								<IonLabel className="ion-text-wrap" position="stacked" mode="ios">
									{field.label}
									{(isRequired() || isHardRequired()) && <span style={{ color: "red" }}>&nbsp;*</span>}
								</IonLabel>
								<IonSelect
									label={""}
									labelPlacement="stacked"
									aria-label={field.label}
									data-testid={`${field.type}:input`}
									// React Hook Form Managed
									ref={fieldRenderProps.ref}
									name={fieldRenderProps.name}
									value={fieldRenderProps.value}
									onIonBlur={fieldRenderProps.onBlur}
									onIonChange={(e) => {
										setTimeout(() => {
											fieldRenderProps.onChange(e);
										}, 0);
									}}
									// Other config
									placeholder={field.description}
									multiple={false}
								>
									{validChoices.map((choice, idx) => (
										<IonSelectOption
											data-testid={`${field.type}:option:${choice.value}`}
											key={`${fieldRenderProps.name}-option-${idx}`}
											value={choice.value}
										>
											{choice.label}
										</IonSelectOption>
									))}
									{hasInvalidLegacyValue
										? <IonSelectOption
											data-testid="invalid-legacy-value"
											key={`${fieldRenderProps.name}-option-legacyValue`}
											value={originalValue}
										>
											{originalValue as string}

										</IonSelectOption>
										: undefined}
								</IonSelect>
								<Divider color="var(--ion-color-medium)" />
								{hasInvalidLegacyValue && (
									<IonNote
										data-testid={`${field.type}:legacy-note`}
										color="danger"
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											margin: ".25rem 0",
											fontSize: ".7rem",
										}}
									>
										<IonIcon
											icon={informationCircleOutline}
											color="danger"
											size="small"
										/>
										<IonText>El valor seleccionado no se ajusta a la especificación actual, y se muestra solo a efectos informativos. Por favor, revise y actualice este campo.</IonText>
									</IonNote>
								)}
								{alert && (
									<IonNote
										data-testid={`${field.type}:note`}
										color={field.alertColor}
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											margin: ".25rem 0",
											fontSize: ".7rem",
										}}
									>
										<IonIcon
											icon={
												field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
											}
											color={field.alertColor}
											size="small"
										/>
										<IonText>{alert}</IonText>
									</IonNote>
								)}
							</IonItem>
						);
					}}
				/>
			)}
		</div>
	);
};

const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(Select, propsAreEqual);
export default Memoized;
