import jexl from "jexl";
import { fromLatLon } from "utm";

import { IDataValue } from "models/FormRecord";

import { Expression } from "../../../../models/Form";
import { Point } from "geojson";

const formatter = new Intl.NumberFormat("en-US", {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

jexl.addFunction("formatCalculation", (value: string) => {
	if (Number.isNaN(Number(value)) || Number(value) === Infinity) {
		return "";
	} else {
		return formatter.format(Number(value)).replace(",", "");
	}
});

jexl.addFunction("startsWith", (str: string, substr: string): boolean => str.startsWith(substr));

jexl.addFunction("includes", (list?: string[] | string, value?: string): boolean => {
	if (list && value) {
		return list.includes(value);
	}
	return false;
});

jexl.addFunction("add", (value: string[]) => {
	if (value.filter((it) => it === undefined || it === "").length === value.length) {
		return "";
	}
	const result = value.reduce((acc, current) => {
		const asNumber = Number(current);
		if (asNumber) {
			return acc + asNumber;
		} else {
			console.log(`jexl add function couldn't add some element ${value}`);
			return acc;
		}
	}, 0);
	return formatter.format(result).replace(",", "");
});

const itemCountFn = (value: unknown[] | undefined) => {
	if (!Array.isArray(value)) return 0;
	return value.filter((it) => !it?._is_deleted)?.length ?? 0;
};
jexl.addTransform("itemCount", itemCountFn);
jexl.addTransform("mapItemCount", (value: unknown[][] | undefined) => (value ?? []).map(itemCountFn));

jexl.addTransform("split", (value: string, sep: string) => value.split(sep));

jexl.addTransform("lower", (value: string) => value.toLowerCase());

jexl.addTransform("geojsonStringToUTMString", (location?: string): string | undefined => {
	if (!location) return undefined;
	try {
		// location is given as a geojson geometry string
		const geometry: Point = JSON.parse(location);
		const utm = fromLatLon(geometry.coordinates[1], geometry.coordinates[0], 30);
		return `${utm.zoneNum}${utm.zoneLetter} ${utm.easting.toFixed(0)} ${utm.northing.toFixed(0)}`;
	} catch (err) {
		console.error("Error parsing coordinates to UTM");
		console.error(err);
	}
	return undefined;
});

jexl.addTransform("arrayMap", (array: string[], mapper: Record<string, string>) => array.map((it) => mapper[it]));

jexl.addTransform("every", (array: string[]) => array.every(Boolean));

const adaptExpressionToValidJexl = (expression: Expression) => {
	return (expression as string)
		.slice(1) // Remove $
		.replaceAll("===", "==") // Jexl doesn't support ===
		.replaceAll("!==", "!=") // Jexl doesn't support !==
		.replaceAll(/\.([0-9]+)/g, "[$1]"); // The syntax for accessing array ids is different
};

export const evaluateExpression = <T>(
	expression: Expression | T,
	context: Record<string, IDataValue>,
	fallback?: T,
): T => {
	if (typeof expression === "string" && expression[0] === "$") {
		let result;
		try {
			result = jexl.evalSync(adaptExpressionToValidJexl(expression), context);
		} catch (e) {
			console.error(e);
		}
		return result !== undefined ? result : fallback;
	}
	return expression as unknown as T;
};
